/* ==========================================================================
/* ===== Toolbar  ===== */
/*========================================================================== */

#toolbar {
	margin: 10px 0;

	p {
		margin: 0px;
		color: $primaryColor;
	}
	
	a {
		font-size: 1.125rem;
	}
	
	@include breakpoint(small only) {
		p {
			text-align: center;
		}
	}

}


/* ==========================================================================
/* ===== Header  ===== */
/*========================================================================== */

section.teaser {
	background-size: cover; background-position: center; 
	
	.teaser-content {
		color: $whiteColor;
		h1 {
			font-size: 3.75rem;
			font-weight: 700;
		}
		h2 {
			font-size: 1.25rem; 
		}
		h3 {
			font-size: 1.2rem; 
			font-weight: 600;
		}

		@include breakpoint(medium down) {
			h1 {
				font-size: 1.75rem;
				font-weight: 700;
			}
			h2 {
				font-size: 0.75rem;
			}
		}
	}

	.bottom-cell{
		background: $grayColor;

		h1 {
			color: #000;
			padding: 40px 0 10px 0;
		}


		i {
			font-size: 2rem;
		}

		@include breakpoint(medium down) {
			h1 {
				font-size: 1.5rem;
			}
		}

		@include breakpoint(small down) {
			h1 {
				font-size: 1rem;
			}
		}
	}

	#typed {
		text-decoration: underline;
		font-weight: 700;
	}

}

.home-page-hero {
	position: relative;
	height: 100vh;
	min-height: 600px;
	background-size: cover;
	background-position: center;
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding: 80px 10px 0 10px;

	&.bottom-edge {
		@include angled-edge('inside bottom', 'upper left', $grayColor, 65);
	}
	
	//* Video Background */
	&.video-bg {
		overflow: hidden;

		.video-foreground,
		.video-background iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;
		}

		.vid-info a { 
			display: block; 
			color: #fff; 
			text-decoration: none; 
			background: rgba(0,0,0,0.5); 
			transition: .6s background; 
			border-bottom: none; 
			margin: 1rem auto; 
			text-align: center; 
		}
			@media (min-aspect-ratio: 16/9) {
			.video-foreground { height: 300%; top: -100%; }
		}

		@media (max-aspect-ratio: 16/9) {
			.video-foreground { width: 300%; left: -100%; }
		}
	}
	
	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	h1 {
		font-size: 2rem;
	}

	.heading {

		&.twentyfiveHeight {
			padding-top: 20vh;
		}

		&.fiftyHeight {
			padding-top: 35vh;
		}

		&.seventyfiveHeight {
			padding-top: 55vh;
		}

		h1 {

			font-size: 3.75rem;
			font-weight: 700;
		}

		h2 {
			font-size: 1.25rem; 
		}
	}


	.action {
		position: absolute;
		bottom: 120px;
		left: 0;
		display: block;
		width: 100%;

		h3 { 
			font-size: 1.2rem; 
			font-weight: 600;
		}

		P {
			color: $whiteColor;
		}

		.scroll-down { 
			@include pulse(1.5s);
			font-size: 3rem;
			text-align: center;
			a {
				color: #fff;
			}
		}
	}

	/* ===== Small Only ===== */
	@include breakpoint(medium down) {
		.heading {
			h1 {
				font-size: 1.75rem;
				font-weight: 700;
			}
			h2 {
				font-size: 0.75rem;
			}
		}
		.action {
			bottom: 80px;
			p {
				display: none;
			}
			h3 {
				text-align: center;
			}
		}
	}

	@include breakpoint(small only) {
		.action {
			h3 {
				font-size: 0.875rem;
			}
		}

	}

	@media screen and #{breakpoint(medium down)} and #{breakpoint(landscape)} {
		min-height: 450px;
	}

}


/* ==========================================================================
/* ===== Slider Intro  ===== */
/*========================================================================== */

.no-js .slider-intro {
	display: none;
}

.slider-intro {
	position: relative; 
	clear: both;

	.slider-actions { 
		position: absolute; 
		z-index: 200; 
		left: 0; 
		top: 50%; 
		width: 100%;
	}

	.owl-prev,
	.owl-next { 
		position: absolute; 
		top: -17px; 
		width: 40px; 
		cursor: pointer; 
		font-size: 24px; 
		color: #fff; 
		text-align: center;
	}

	.owl-prev,
	.owl-next {
		transition: color .2s ease-out; 
	}

	.owl-prev:hover,
	.owl-next:hover { 
		color: $grayColor;
	}

	.owl-prev {
		left: 30px;
	}

	.owl-next {
		right: 30px;
	}

	.slides {
		list-style: none outside none; 
		margin: 0px; 
		padding: 0px;
	}

	.slide-alt {
		position: relative; /*min-height: 80vh; */

		&.fiftyHeight {
			padding-top: 50vh;
		}

		&.seventyfiveHeight {
			padding-top: 75vh;
		}

		&.fullHeight {
			padding-top: 100vh;
		}
		
		.slide-image {
			position: absolute; 
			left: 0; 
			top: 0; 
			width: 100%; 
			height: 100%;

			.overlay  {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				height: 100%;
				width: 100%;
				transition: .5s ease;
				z-index: 0;
			}
		}

		.slide-content {
			position: absolute;
			left: 0; 
			top: 50%; 
			width: 100%; 

			& {
				transform: translateY(-50%); 
			}

			.slide-content-inner { 
				max-width: 500px; 
				padding: 40px; 
				background: $whiteColor; 
				color: #fff;  
			}
			p { 
				margin-bottom: 22px;
				color: $textColor;
				font-size: 1rem;
			}	
		}

		.slide-title {
			font-size: 1.75rem;
			font-weight: 600; 
			color: $primaryColor; 
			line-height: 1.3;	
		}

		
	}

	.active {
		.slide-alt {
			.slide-content {
				.slide-content-inner {
					@include mui-animation(slide($direction: down, $amount: 120%));
					animation-duration: 1s;
				}
			}
		}
	}


	//* ===== Small Desktop ===== */
	@include breakpoint(medium down) {
		& {
			.slide-alt {
				&.fiftyHeight {
					padding-top: 20vh;
				}

				&.seventyfiveHeight {
					padding-top: 35vh;
				}

				&.fullHeight {
					padding-top: 100vh;
				}
			}
		}
	}

	//* ===== Tablet Portrait ===== */
	@media screen and #{breakpoint(landscape)} and #{breakpoint(medium)} {
	

		.slide-alt {
			min-height: 400px;
			.slide-content { 
				.slide-content-inner { margin: 0; }
			}
		}
	}

	//* ===== Mobile ===== */
	@include breakpoint(small only) {

		.owl-prev { left: 10px; width: auto; font-size: 18px; }
		.owl-next { right: 10px; width: auto; font-size: 18px; }

		.slide-alt { 

			&.fiftyHeight {
			padding-top: 20vh;
			}

			&.seventyfiveHeight {
				padding-top: 35vh;
			}

			&.fullHeight {
				padding-top: 100vh;
			}

			.slide-title { font-size: 18px; }
			.slide-content p { font-size: 0.9375rem; }
		}

	}
}